export enum TruecallerLoginUrls {
  loginUrl = "/neo/user/authorization/v2/truecaller_response_from_token",
  registerUrl = "/neo/user/authorization/v2/truecaller_user_registration"
}

export enum AuthorizationUrls {
  registerUrl = "/neo/user/authorization/v2/user_registration",
  loginUrl = "/neo/user/authorization/v2/login",
  sendOtpV2 = "/neo/user/authorization/v2/send_otp",
  otpVerifyV2 = "/neo/user/authorization/v2/verify_otp",
  sendOtp = "/neo/user/authorization/send_otp",
  otpVerify = "/neo/user/authorization/verify_otp",
  socialLogin = "/neo/user/authorization/social_login",
  onBoardPage = "/neo/user/authorization/onboarding_screen_data",
  verifyToken = "/neo/auth/verify/v1",
  getToken = "/neo/auth/get/v1",
  deleteAccount = 'neo/user/authorization/delete-account'
}

export enum ProfileUrls{
  userAboutme = "/neo/onboarding/aboutme",
  updateProfile = "/neo/onboarding/updateprofile",
  cityStateByPostal = "/neo/onboarding/cityStateByPostal",
  getUserAddress = "/neo/onboarding/useraddresses",
  getPostalCodeInfo = "/neo/onboarding/getpostalcodeinfo",
  addAddress = "/api/shop/address",
  deleteAddress = "/neo/onboarding/deleteaddresses",
  saveAddress = "/neo/onboarding/saveaddress",
  discoverPersona = "/neo/onboarding/persona",
  getMembershipPlans = "/neo/onboarding/membership_plans",
}

export enum RecoUrls {
  neoRecoUrl = '/neo/reco/product'
}
export enum LipstickSurvey {
  claimFreeGiftUrl = "/api/shop/variantProductsOfOffer",
  addFreebieToCartUrl = "api/shop/addfreebietocart",
}
export enum PriceReveal {
  productListingUrl = "neo/utils/prebook/listing",
  beautybagUrl = "neo/utils/prebook/beautybag",
  addRemoveItemUrl = "neo/utils/prebook/addtobag"
}
export enum commonApiUrls {
  categoryMenu = "/api/v2/shop/category_menu",
  initState = "/neo/onboarding/initstate",
  feedback = "/api/common/feedback",
  appDeeplink = "/api/v2/shop/campaign_track_source",
  bottomNavigation = "/neo/onboarding/bottom-navigation",
  searchInit = "/api/v2/shop/searchinit",
  superMenu = "/neo/onboarding/super_menu?fields=menus",
  staticpages = "/api/common/staticpages",
  brands = "/neo/onboarding/brands",
  headerMenu = "/neo/onboarding/header-menu?fields=menus",
}
export enum FhUrls {
  detailsUrl = "/neo/merch/fh/listing",
  widgetUrl = "/neo/merch/fh/widget",
  thankYouPageUrl = "/neo/merch/fh/thankyou"
}
export enum merchUrls {
  filtersUrl = "/neo/merch/filters/v2",
  listingUrl = "/neo/merch/listing",
  suggestionsUrl = "/neo/merch/suggestions"
}
export enum utilsUrls {
  metaInfoUrl = "/neo/utils/meta-info",
}
export enum catalogUrls {
  basicInfo = "/neo/catalog/basicinfo",
  retrivePrice = "/neo/catalog/retrieveprice",
  reviews = "/neo/catalog/reviews",
  reviewImages = '/neo/catalog/reviewimages',
  description = '/neo/catalog/description',
  qna = '/neo/catalog/qna',
  productGallery = "/neo/catalog/gallery"
}
export enum cartUrls {
  listCoupons = "/neo/coupon/listing-coupon",
  applyCoupon = "/neo/coupon/apply-coupon",
  getEdd = "/neo/cart/get-edd-of-cart",
  pincodeCheck = "/neo/cart/pincode-check",
  addToCart = "/neo/cart/add-to-cart",
  removeItem = "/neo/cart/remove-item",
  priceDrop = "/neo/cart/price-drop",
  cart = "/neo/cart/detail",
  paymentOptions = "/neo/payment/options",
  upiValidation = "/neo/juspay/upi-validation",
  checkout = "/neo/cart/checkout",
}
export enum shopUrls {
  createOrder = "/api/shop/createorder",
  productWishlist = "/neo/onboarding/wishlist_v2",
  storyWishlist = "/neo/onboarding/wishlist",
  getFreebiesOffers = "/api/v2/shop/getoffers_Onid",
  addFreebieToCart = "api/v2/shop/addfreebietocart",
  trackShipment = "/api/account/user/tracking_details",
  cardValidations = "/api/shop/card_validations",
  createWallet = "/api/shop/create_wallet_v2",
  linkWallet = "/api/shop/link_wallet_v2",
  addMoney = "/api/shop/wallet_add_money",
  walletDebit = "api/shop/direct_debit_v2"
}

export enum supportUrls {
  getTicketInfo = "/neo/freshdesk-service/api/ticket/option",
  searchOrderInfo = "/neo/freshdesk-service/api/ticket/search",
  createTicket = "/neo/freshdesk-service/api/ticket/create"
}